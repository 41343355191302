import React, { createContext, useState, useEffect } from 'react';
import i18n from '@lib/i18n';
import { getDynamicConfig } from '@vl-core/hooks/useConfig';
import { useRouter } from 'next/router';
import Themes from './themes';

type ThemeContextWrapperProps = {
  children: React.ReactNode;
};

type Theme = (typeof Themes)[keyof typeof Themes];

type ThemeContextType = {
  theme: Theme;
  selectTheme: (newThemeSelection: string) => Promise<void>;
  devOverride: boolean;
  overrideThemeDetection: () => void;
};

const ThemeContext = createContext<ThemeContextType>({
  theme: Themes.hbs,
  selectTheme: async () => {},
  devOverride: false,
  overrideThemeDetection: () => {}
});

const { Provider, Consumer } = ThemeContext;

type TranslationsCache = Map<string, any>;

const translationsCache: TranslationsCache = new Map();

const guestAccessPagesThemeConfig = {
  NHS: {
    route: '/nhslink',
    themeKey: Themes.bob.id
  },
  AXA: {
    route: '/linklogin',
    themeKey: Themes.axa.id
  }
};

const getGuestAccessTheme = (pathname: string): string | null => {
  return Object.values(guestAccessPagesThemeConfig).find((config) => pathname.includes(config.route))?.themeKey || null;
};

const ThemeContextWrapper = ({ children }: ThemeContextWrapperProps) => {
  const router = useRouter();
  const { pathname } = router;
  const [theme, setTheme] = useState<Theme>(Themes.hbs);
  const [devOverride, setDevOverride] = useState(false);

  useEffect(() => {
    getDynamicConfig().then(({ config }) => {
      if (devOverride) return;

      const guestAccessThemeOverride = getGuestAccessTheme(pathname);

      const newTheme = guestAccessThemeOverride || config.THEME;

      if (newTheme.toLowerCase() === theme.id) return;
      selectTheme(newTheme).then();
    });
  });

  const selectTheme = async (themeName: string) => {
    const themeKey = themeName.toLowerCase() as keyof typeof Themes;
    const newTheme = Themes[themeKey] as Theme;

    if (!i18n.isInitialized) {
      await i18n.reloadResources();
    }

    if (!newTheme) {
      console.warn(`Missing theme for "${themeKey}", defaulting to "hbs"`);
      setTheme(Themes.hbs);
    } else {
      try {
        if (translationsCache.has(newTheme.id)) {
          const cachedTranslations = translationsCache.get(newTheme.id);
          i18n.addResources('en', 'common', cachedTranslations);
        } else {
          const clientTranslationsResponse = await fetchThemeTranslationDefinitions(newTheme.id);
          const clientTranslations = await clientTranslationsResponse.json();

          if (clientTranslationsResponse.ok) {
            i18n.addResources('en', 'common', clientTranslations);
            translationsCache.set(newTheme.id, clientTranslations);
          } else {
            console.error(`Missing theme definition for client "${newTheme.id}".`);
          }
        }
        setTheme(newTheme);
      } catch (error) {
        console.error('Error fetching theme translation:', error);
      }
    }
  };

  const fetchThemeTranslationDefinitions = (name: string) => {
    const url = `${process.env.BASEPATH || ''}/locales/${name}/en/common.json`;
    return fetch(url);
  };

  const overrideThemeDetection = () => setDevOverride(true);

  return <Provider value={{ theme, selectTheme, devOverride, overrideThemeDetection }}>{children}</Provider>;
};

export { ThemeContextWrapper, ThemeContext, Consumer };
