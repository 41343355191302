import * as z from 'zod';
import { Options } from './helpers/type-helpers';
import { axiosInstance, toastOnError } from '@vl-core/api';
import { joinKeys } from './helpers/joinKeys';
import { safeParse } from './helpers/safeParse';

export const path = '/meta/misc/APPUSER';

const LanguageSchema = z.object({
  lang_code: z.string(),
  lang_desc: z.string()
});
type Language = {
  lang_code: string;
  lang_desc: string;
};

const IreqStateSchema = z.object({
  ireq_state_code: z.string(),
  ireq_state_desc: z.string(),
  ireq_state_internal: z.string()
});
type IreqState = {
  ireq_state_code: string;
  ireq_state_desc: string;
  ireq_state_internal: string;
};

const GenIveStateSchema = z.object({
  geninv_randox_status_id: z.string(),
  geninv_status_code: z.string(),
  geninv_status_desc: z.string()
});
type GenIveState = {
  geninv_randox_status_id: string;
  geninv_status_code: string;
  geninv_status_desc: string;
};

const FieldValidatorSchema = z.object({
  rule_code: z.string(),
  regex: z.string().nullable(),
  field_max_length: z.number(),
  nhs_number_flag: z.coerce.boolean(),
  sample_data: z.string(),
  error_text: z.string(),
  client_code: z.string().nullable()
});
type FieldValidator = {
  rule_code: string;
  regex: string | null;
  field_max_length: number;
  nhs_number_flag: boolean;
  sample_data: string;
  error_text: string;
  client_code: string | null;
};

export const MiscMetaSchema = z.object({
  /** sic */
  langauges: z.array(LanguageSchema),
  ireq_states: z.array(IreqStateSchema),
  info_tags: z.array(z.string()),
  filter_tags: z.array(z.string()).nullable(),
  timezones: z.array(z.string()),
  field_validators: z.array(FieldValidatorSchema).nullable(),
  geninv_states: z.array(GenIveStateSchema)
});
export type MiscMeta = {
  /** sic */
  langauges: Language[];
  ireq_states: IreqState[];
  info_tags: string[];
  filter_tags: string[] | null;
  timezones: string[];
  field_validators: FieldValidator[] | null;
  geninv_states: GenIveState[];
};

export const ResponseSchema = z.object({
  meta: z.array(MiscMetaSchema)
});
export type Response = {
  meta: MiscMeta[];
};

export const ParamsSchema = z.object({
  rule_code: z.string().optional()
});
export type Params = {
  rule_code?: string;
};

export const getMetaMisc = async (params?: Params): Promise<MiscMeta[]> =>
  axiosInstance
    .get(path, {
      params
    })
    .then((res) => safeParse(res.data, z.array(MiscMetaSchema)));

export const getMetaMiscQuery = (params?: Params, options?: Options) =>
  ({
    queryKey: joinKeys(path, params),
    queryFn: () => getMetaMisc(params),
    select: (data: MiscMeta[]) => data[0],
    useErrorBoundary: options?.skipToast ? false : toastOnError,
    staleTime: Infinity
  }) as const;

// Tests
let rs = {} as z.infer<typeof ResponseSchema>;
let rt = {} as Response;
let ps = {} as z.infer<typeof ParamsSchema>;
let pt = {} as Params;

rs = rt;
rt = rs;
ps = pt;
pt = ps;
