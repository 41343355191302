import ServiceApi from '../services/service.api';

const baseServiceUrl = `/randox`;

const ApiRandox = {
  getRandoxByID: (order_number: string, pat_user_guid: string) => {
    const params = {
      order_number,
      action: 'updateOrderStatus',
      pat_user_guid
    };
    return ServiceApi.post(`${baseServiceUrl}`, params);
  },
  assignOrderId: (order_number: string, lab_test_ids: number[], pat_user_guid: string) => {
    const params = {
      order_number,
      lab_test_ids,
      action: 'assignOrderId',
      pat_user_guid
    };
    return ServiceApi.post(`${baseServiceUrl}`, params);
  }
};

export default ApiRandox;
