import ServiceApi from '../services/service.api';

const baseServiceUrl = `/labs`;

const ApiLabs = {
  getLabs: (query: any) => {
    const params = {
      page: 1,
      status: '',
      sort: 'desc',
      ...query
    };
    // @ts-expect-error
    return ServiceApi.get(`${baseServiceUrl}/labs/${ServiceApi.userGuid}`, { params });
  }
};

export default ApiLabs;
