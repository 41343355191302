import getConfig from 'next/config';
import backendFetch from '@lib/backendFetch';
import { unpackJson } from 'store/actions/action';
import { Auth } from 'aws-amplify';
import * as actionTypes from '@actionTypes';
import { userPolicy } from 'store/actions/auth';
import type { CognitoUser, ChallengeName } from 'amazon-cognito-identity-js';
import { userFetchResponse, userLogoutResponse, userRole } from 'store/actions/userReducers';
import { getRuntimeConfig } from 'vl-common/src/hooks/Runtime';
import type { Dispatch } from 'react-redux';

// AWS have screwed up these type defs ... if I take it from the proper location I get a load of undefined imports.
// import { UsernamePasswordOpts } from '@aws-amplify/auth/src/types/Auth';
type UsernamePasswordOpts = {
  username: string;
  password: string;
  validationData?: { [key: string]: any };
};

export async function getUser(username: string, token: string, dispatch: Dispatch, params = {}) {
  const { publicRuntimeConfig } = getConfig();
  const { API_URL } = publicRuntimeConfig;
  const url = new URL(`${API_URL}/users/user/${username}`);

  // @ts-ignore
  Object.entries(params).forEach(([k, v]) => url.searchParams.set(k, v));

  const ret = await backendFetch(url.toString(), {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    // @ts-ignore
    response: true,
    body: undefined,
    dispatch
  });

  if (!ret.ok) {
    throw Error(`Could not get user details for ${username}`);
  }

  return { data: await unpackJson(ret) };
}

export const userFetch =
  (params: Record<string, string> | undefined = undefined) =>
  (dispatch: Dispatch) => {
    return Auth.currentSession()
      .then(async () => {
        const tokens = await Auth.currentSession();
        const token = tokens.getIdToken().getJwtToken();

        const { sub: userGuid, aud } = tokens.getIdToken().payload;
        const { data: vlUser } = await getUser(userGuid, token, dispatch, params);

        function isRestricted() {
          const { APP_CLIENT_ID, APP_CLIENT_ID2 } = getRuntimeConfig();

          if (aud === APP_CLIENT_ID) return false;
          if (aud === APP_CLIENT_ID2) return true;

          if (Auth.authenticatedUser) return false;

          throw Error('Could not determine if the user is restricted');
        }

        dispatch(userRole(vlUser.user_type_code));
        const user = await dispatch(userFetchResponse(actionTypes.AUTH_SUCCESS, vlUser, token, isRestricted()));
        await dispatch(userPolicy('USER_POLICY'));
        return user;
      })
      .catch((err) => {
        console.error({ err });
        dispatch(userRole(null));
        dispatch(userLogoutResponse());

        // NB: unusual, but throw this applied action so that callers can use try/catch rather
        // than just check the type code
        throw dispatch({ type: actionTypes.AUTH_FAIL, ...err }, false);
      });
  };

export const userSendCode = (code: string, params: any, cognito: CognitoUser) => async (dispatch: Dispatch) => {
  try {
    const data = await Auth.sendCustomChallengeAnswer(cognito, code);

    if (data) return dispatch(userFetch(params));
  } catch (err) {
    console.error({ err });
    if (
      err &&
      typeof err === 'object' &&
      'code' in err &&
      'message' in err &&
      err.code === 'NotAuthorizedException' &&
      err.message === 'Invalid session for the user.'
    ) {
      throw dispatch({ type: actionTypes.AUTH_SESSION_EXPIRED }, false);
    }
    // fall through
  }

  // NB: unusual, but throw this applied action so that callers can use try/catch rather
  // than just check the type code
  throw await dispatch({ type: actionTypes.AUTH_LOGIN_FAIL }, false);
};

// this is called when a restricted access (a.k.a. "guest") login is attempted
export const userLinkLogin =
  (dob: string, membershipNumber: string, params: Record<string, string>, cognito: CognitoUser) =>
  async (dispatch: Dispatch) => {
    const makeAnswer = (dob: Date, membershipNumber: string) => {
      const epochTimeStr = String(dob.getTime() / 1000);
      const membershipNumberStr = membershipNumber.replace(/\s/g, '');

      return `${epochTimeStr}|${membershipNumberStr}`;
    };

    try {
      const challengeResponse = await Auth.sendCustomChallengeAnswer(
        cognito,
        makeAnswer(new Date(dob), membershipNumber)
      );
      if (challengeResponse) return dispatch(userFetch(params));
    } catch (err) {
      console.log({ err });
      throw await dispatch({ type: actionTypes.AUTH_LOGIN_FAIL, ...(typeof err === 'object' ? err : {}) }, false);
    }

    return dispatch({ type: actionTypes.AUTH_LOGIN_FAIL });
  };

export const userLogin =
  (request: UsernamePasswordOpts, params?: Record<string, string>, cogito?: CognitoUser) =>
  async (dispatch: Dispatch) => {
    try {
      const cogUser: CognitoUser = cogito || (await Auth.signIn(request));
      const { challengeName } = cogUser;
      const followOnActions: Partial<Record<ChallengeName, string>> = {
        CUSTOM_CHALLENGE: actionTypes.AUTH_LOGIN_CODE,
        NEW_PASSWORD_REQUIRED: actionTypes.AUTH_LOGIN_NEWPASSOWRD
      };

      if (challengeName) {
        const followOnAction: string | undefined = followOnActions[challengeName];

        if (followOnAction) {
          return dispatch({ type: followOnAction, ...cogUser });
        }
      }

      return dispatch(userFetch(params));
    } catch (err) {
      console.log({ err });
      // NB: unusual, but throw this applied action so that callers can use try/catch rather
      // than just check the type code
      throw await dispatch({ type: actionTypes.AUTH_LOGIN_FAIL, ...(typeof err === 'object' ? err : {}) }, false);
    }
  };
