import * as z from 'zod';

export const SurveySchema = z.object({
  patient_user_guid: z.string(),
  surveyjs_id: z.number(),
  surveyjs_def_id: z.number(),
  survey_def_id: z.number(),
  case_id: z.union([z.number(), z.null()]),
  appt_id: z.union([z.number(), z.null()]),
  created_date: z.coerce.date(),
  updated_date: z.coerce.date(),
  completed_flag: z.boolean(),
  always_edit: z.boolean(),
  completed_date: z.coerce.date(),
  cat_code: z.string(),
  cat_name: z.string(),
  area_code: z.union([z.null(), z.string()]),
  area_name: z.union([z.null(), z.string()]),
  title: z.string(),
  shortname: z.string(),
  event_code: z.string(),
  outcome_flag: z.boolean(),
  outcome_mandatory_flag: z.boolean(),
  clin_editable: z.boolean(),
  response: z.union([z.null(), z.string()]),
  definition_pregenerated: z.number(),
  pathway: z.string().nullable()
});

export type Survey = z.infer<typeof SurveySchema>;
