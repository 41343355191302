import { useEffect, useState } from 'react';
import { TableColumnType } from 'antd';

const breakPoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
  xxxl: 1920
};

export type Breakpoint = keyof typeof breakPoints;

export function useReactiveColumns<Row>(tableColumn: TableColumnType<Row>[], currentBreakPoint: Breakpoint) {
  const [displayColumnList, setDisplayColumnList] = useState([] as TableColumnType<Row>[]);
  const [hideColumnList, setHideColumnList] = useState([] as TableColumnType<Row>[]);
  const displayColumns = (lhs?: Breakpoint, rhs?: Breakpoint) => {
    const largerBreakPoints = {
      xs: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl'],
      sm: ['sm', 'md', 'lg', 'xl', 'xxl', 'xxxl'],
      md: ['md', 'lg', 'xl', 'xxl', 'xxxl'],
      lg: ['lg', 'xl', 'xxl', 'xxxl'],
      xl: ['xl', 'xxl', 'xxxl'],
      xxl: ['xxl', 'xxxl'],
      xxxl: ['xxxl']
    };
    if (!lhs) {
      return true;
    }
    const larger = largerBreakPoints[lhs];
    return rhs ? larger.includes(rhs) : false;
  };

  useEffect(() => {
    setDisplayColumnList(tableColumn.filter((item) => displayColumns(item.responsive?.[0], currentBreakPoint)));
    setHideColumnList(tableColumn.filter((item) => !displayColumns(item.responsive?.[0], currentBreakPoint)));
  }, [currentBreakPoint, tableColumn]);

  return [displayColumnList, hideColumnList];
}
export default useReactiveColumns;
