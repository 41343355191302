import { useMemo } from 'react';
import { useViewportSize } from './useWindowSize';

const breakPoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
  xxxl: 1920
};

export type Breakpoint = keyof typeof breakPoints;

export function useWidthBreakpoint() {
  const { width: actualWidth } = useViewportSize();
  const currentBreakPoint = useMemo(() => {
    const [breakPoint] =
      (Object.entries(breakPoints) as [Breakpoint, number][]).reverse().find(([, size]) => size <= actualWidth) || [];

    return breakPoint || 'xs';
  }, [actualWidth]);

  return currentBreakPoint;
}

export default useWidthBreakpoint;
