import React, { useState } from 'react';
import { User } from '../../schemas/shared';

type AppointmentContextWrapperProps = {
  children: React.ReactNode;
};

type AppointmentState = {
  patientData: User;
  appointment_id: null | number;
  appointmentType: null | string;
  appt_auth_from_date: null | Date;
  appt_auth_end_date: null | Date;
  appt_type_code: null | string;
  callType: null | string;
  caseId: null | number;
  clinicianAreas: null | string[];
  clinicianId: null | string;
  clinicianRescheduling: null | boolean;
  clinicianSpecCat: null | string;
  clinicianTypeCode: null | string;
  date: null | string;
  expiryTime: null | number;
  patientBookedAppointment: null | boolean;
  patientName: null | string;
  patientSpecArea: null | string;
  type: null | string;
  typeCode: null | string;
  userId: null | string;
  caseUrgency: null | 'ROU' | 'URG';
};

const blankAppointmentState: AppointmentState = {
  patientData: null,
  appointmentId: null,
  appointmentType: null,
  appt_auth_from_date: null,
  appt_type_code: null,
  callType: null,
  caseId: null,
  clinicianAreas: null,
  clinicianId: null,
  clinicianRescheduling: null,
  clinicianSpecCat: null,
  clinicianTypeCode: null,
  date: null,
  expiryTime: null,
  patientBookedAppointment: null,
  patientName: null,
  patientSpecArea: null,
  type: null,
  typeCode: null,
  userId: null,
  caseUrgency: null
};

type TAppointmentContext = {
  appointment: AppointmentState;
  updateAppointment: any;
  clearAppointment: any;
};

const initialAppointmentContext: TAppointmentContext = {
  appointment: blankAppointmentState,
  updateAppointment: () => {},
  clearAppointment: () => {}
};

const AppointmentContext = React.createContext(initialAppointmentContext);
const { Provider, Consumer } = AppointmentContext;

const AppointmentContextWrapper = ({ children }: AppointmentContextWrapperProps) => {
  const [appointment, setAppointment] = useState<AppointmentState>(blankAppointmentState);
  const sessionStorageKey = 'appointment';

  const setAppointmentInSessionStorage = (appointmentInfo) =>
    sessionStorage.setItem(sessionStorageKey, JSON.stringify(appointmentInfo));

  const getAppointmentInSessionStorage = () => JSON.parse(sessionStorage.getItem(sessionStorageKey));

  const updateAppointment = (updates, oldAppointmentObject = null) => {
    // oldAppointmentObject dates are returned as Strings from session storage
    const authStartToDate = (() => {
      if (updates?.appt_auth_from_date) {
        return updates.appt_auth_from_date;
      }
      if (oldAppointmentObject?.appt_auth_from_date) {
        return new Date(oldAppointmentObject.appt_auth_from_date);
      }
      return null;
    })();
    const authEndToDate = (() => {
      if (updates?.appt_auth_end_date) {
        return updates.appt_auth_end_date;
      }
      if (oldAppointmentObject?.appt_auth_end_date) {
        return new Date(oldAppointmentObject.appt_auth_end_date);
      }
      return null;
    })();
    const newAppointment = oldAppointmentObject ?? { ...appointment };
    const appointmentUpdates = {
      ...updates,
      expiryTime: new Date().getTime() + twentyMinutes,
      appt_auth_from_date: authStartToDate,
      appt_auth_end_date: authEndToDate
    };

    Object.assign(newAppointment, appointmentUpdates);
    console.log('Appointment Context - Updated', { oldAppointmentObject, newAppointment });

    setAppointment(newAppointment);
    setAppointmentInSessionStorage(newAppointment);
    return newAppointment;
  };

  const twentyMinutes = 1200000;

  const clearAppointment = () => {
    const blankAppointmentWithDate = {
      ...blankAppointmentState,
      expiryTime: new Date().getTime() + twentyMinutes
    };
    console.log('Appointment Context - Cleared');
    setAppointment(blankAppointmentWithDate);
    setAppointmentInSessionStorage(blankAppointmentWithDate);
  };

  const maintainStateBetweenRefreshes = () => {
    if (appointment === blankAppointmentState && typeof window !== 'undefined') {
      const previouslyStoredAppointment = getAppointmentInSessionStorage();
      const timeNow = new Date().getTime();
      if (previouslyStoredAppointment && previouslyStoredAppointment.expiryTime) {
        const { expiryTime } = previouslyStoredAppointment;
        const appointmentIsStale = timeNow > expiryTime;
        if (appointmentIsStale) {
          clearAppointment();
        } else {
          updateAppointment({ expiryTime: new Date().getTime() + twentyMinutes }, previouslyStoredAppointment);
        }
      } else {
        const blankAppointmentWithDate = {
          appointmentId: null,
          appointmentType: null,
          callType: null,
          caseId: null,
          clinicianAreas: null,
          clinicianId: null,
          clinicianRescheduling: null,
          clinicianSpecCat: null,
          clinicianTypeCode: null,
          date: null,
          expiryTime: new Date().getTime() + twentyMinutes,
          patientBookedAppointment: null,
          patientName: null,
          patientSpecArea: null,
          type: null,
          typeCode: null,
          userId: null
        };
        console.log('Appointment Context - Setting blank appointment in session storage');
        setAppointmentInSessionStorage(blankAppointmentWithDate);
      }
    }
  };
  maintainStateBetweenRefreshes();

  return <Provider value={{ appointment, updateAppointment, clearAppointment }}>{children}</Provider>;
};

export { AppointmentContextWrapper, AppointmentContext, Consumer };
