import { wait } from '../wait';

interface FetchOptions {
  dispatch?: any;
  unauthenticated?: boolean;
  disabledBackoff?: boolean;
  currentBackoff?: number;
}

const statusIsGood = (response): boolean => {
  return response.status === 200 || response.status === 201;
};

const stripOutHighLevelWrappers = (originalJson) => {
  const thereIsOnlyOneKeyAtTheHighestLevelOfJson = Object.keys(originalJson).length === 1;
  const firstValueInJson = originalJson[Object.keys(originalJson)[0]];
  const theKeysValueIsAnArray = Array.isArray(firstValueInJson);
  const jsonContainsAWrapperObject = thereIsOnlyOneKeyAtTheHighestLevelOfJson && theKeysValueIsAnArray;
  if (jsonContainsAWrapperObject) {
    return firstValueInJson[0];
  }
  return originalJson;
};

/** Call a function with an exponential backoff */
export const callWithRetry = async (func: Function, depth: number = 0, maxRetryDepth: number = 3) => {
  try {
    return await func();
  } catch (error) {
    if (depth > maxRetryDepth) {
      throw error;
    }
    await wait(2 ** depth * 10);

    return callWithRetry(func, depth + 1);
  }
};

const fetchWrapper = async (input: string, init?: RequestInit & FetchOptions): Promise<Response> => {
  console.log('Using fetch wrapper');

  const retryableFetch = () =>
    new Promise((resolve, reject) => {
      fetch(input, init).then((response) => {
        if (statusIsGood(response)) {
          const responseJson = response.json();
          resolve(responseJson);
        } else reject();
      });
    });
  const retryDepth = 0;
  const maxRetryDepth = 2;

  const responseJson = await callWithRetry(retryableFetch, retryDepth, maxRetryDepth);
  const processedResponseJson = stripOutHighLevelWrappers(responseJson);
  return processedResponseJson;
};

export default fetchWrapper;
